// // Helper function to convert date-time string to Date object
export const parseDateTimeUser = dateTimeString => new Date(dateTimeString);

// Convert date-time string to a formatted string without milliseconds and timezone
export function convertDateFormatUser(dateTimeString) {
    const dateObj = new Date(dateTimeString);
    const adjustedTime = new Date(dateObj.getTime() - (dateObj.getTimezoneOffset() * 60000)).toISOString();
    return adjustedTime.slice(0, 19); // 2024-08-01T09:00:00
}

// Function to round the time to the nearest 10 minutes
export function roundToNearestTenMinutes(date) {
    const minutes = date.getMinutes();
    const roundedMinutes = Math.ceil(minutes / 10) * 10;
    date.setMinutes(roundedMinutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

// This code is for merging

// // Function to divide time slots into intervals without overlap and merge as well
export const userDivideTimeSlots = (arr, durationInMinutes) => {

    // attempting merge working fine
    const duration = durationInMinutes * 60 * 1000; // Convert minutes to milliseconds
    const overlap = 10 * 60 * 1000; // Convert overlap minutes to milliseconds

    let now = parseDateTimeUser(new Date());
    now = roundToNearestTenMinutes(now);
    // To keep track of global time slots across all users to merge matching time slots
    const globalTimeSlotsMap = new Map();

    // Process each day's time slots
    return arr.map(day => {
        const newTimeSlots = [];

        day.timeSlots.forEach(slot => {
            let start = parseDateTimeUser(slot.from);
            const end = parseDateTimeUser(slot.to?.split("+")[0]);

            // Skip this time slot if its end time is before the current time
            if (end <= now) return;

            // Adjust the start time if it falls before the current time
            if (start < now) {
                start = now;
            }
            else {
                // Round the next time slot start time if it starts after the current time
                start = roundToNearestTenMinutes(start);
            }

            while (start < end) {
                const intervalEnd = new Date(start.getTime() + duration);
                const actualEnd = intervalEnd > end ? end : intervalEnd;

                if (actualEnd > start) {
                    if (actualEnd - start >= duration) {
                        const startStr = convertDateFormatUser(start);
                        const endStr = convertDateFormatUser(actualEnd) + '+00:00';
                        const slotKey = `${startStr}-${endStr}`;
                        // If the same time slot exists globally, skip adding it again
                        if (!globalTimeSlotsMap.has(slotKey)) {
                            globalTimeSlotsMap.set(slotKey, {
                                from: startStr,
                                to: endStr
                            });
                            // Add to the current day's time slot list
                            newTimeSlots.push({
                                from: startStr,
                                to: endStr
                            });
                        }
                    }
                }

                // Move start forward by the duration amount for the next interval
                start = new Date(start.getTime() + overlap);
                if (start >= end) break;
            }
        });

        // Return the day with the new time slots (without affecting other objects)
        return { ...day, timeSlots: newTimeSlots };
    });
};